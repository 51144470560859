import { AtomIcon, DropletsIcon, ThermometerIcon } from 'lucide-react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge'
import type { DetailedDevice } from '@/data/device'
import { shortLocalizedDateWithTime } from '@/utils/dateHelpers'
import { operatingWellStatuses } from '@/utils/statusHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import { getTempSuffix } from '@/utils/unitHelpers'

import { Card } from '../ui/card'

import BunkerOverview from './BunkerOverview'
import GenericErrorBoundary from './errors/GenericErrorBoundary'
import HydroponicsOverview from './HydroponicsOverview'
import DeviceTypeIcon from './icon-resolvers/DeviceTypeIcon'

function DeviceIllustrationCard({ device }: { device: DetailedDevice }) {
  const { t } = useTranslation(['app', 'common'])

  const hasMoisture =
    (device?.data?.moistureLeft && device.data.moistureLeft > 0) ||
    (device?.data?.moistureRight && device.data.moistureRight > 0)
  const hasEC =
    (device?.data?.ecLeft && device.data.ecLeft > 0) ||
    (device?.data?.ecRight && device?.data?.ecRight > 0)
  const hasTemperature =
    (device?.data?.temperatureLeft && device.data.temperatureLeft > 0) ||
    (device?.data?.temperatureRight && device?.data?.temperatureRight > 0)

  const hasDetailedData = hasMoisture || hasEC || hasTemperature

  const tempSuffix = getTempSuffix(device?.temperatureUnit)

  return (
    <Card variant="default" className="border-darkSand p-4">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <DeviceTypeIcon type={device.type} />
          <div className="flex flex-col items-baseline truncate">
            <span className="text-xs text-concreteGray">
              {capitalizeFirstCharacter(t(device.type, { ns: 'common' }))}
            </span>
            <h2 className="font-bold text-concreteGray">{device.name}</h2>
          </div>
        </div>
        <div className="hidden sm:block">
          {!operatingWellStatuses.includes(device.status) ? (
            <DeviceStatusBadge status={device.status} />
          ) : null}
        </div>
      </div>
      <hr />
      <div className="mb-4 mt-4 overflow-hidden">
        <ErrorBoundary fallback={<GenericErrorBoundary />}>
          {device.type === 'bunker' ? (
            <BunkerOverview device={device} />
          ) : (
            <>
              <div className="mb-8">
                <div className="space-x-2">
                  <span className="text-sm text-concreteGray">
                    {t('device.details.imei')}:
                  </span>
                  <span className="ml-2 text-sm font-semibold text-concreteGray">
                    {device.imei}
                  </span>
                </div>
                <div className="space-x-2">
                  <span className="text-sm text-concreteGray">
                    {t('device.details.firmware_version')}:
                  </span>
                  <span className="ml-2 text-sm font-semibold text-concreteGray">
                    {device.data?.firmwareVersion}
                  </span>
                </div>
              </div>

              <HydroponicsOverview device={device} />

              {hasDetailedData ? (
                <div className="mb-8 mt-16 flex flex-wrap gap-4 sm:flex-nowrap">
                  {hasMoisture ? (
                    <Card className="w-full border-dirtySand/40 bg-darkSand p-4 sm:w-auto">
                      <div className="flex items-center gap-2 pb-2">
                        <DropletsIcon className="h-4 w-4" />
                        <h4>Moisture</h4>
                      </div>

                      <div className="flex items-center gap-2 text-sm text-concreteGray">
                        <span>Left:</span>
                        <span className="font-semibold">
                          {device.data?.moistureLeft}%
                        </span>
                      </div>

                      <div className="flex items-center gap-2 text-sm text-concreteGray">
                        <span>Right:</span>
                        <span className="font-semibold">
                          {device.data?.moistureRight}%
                        </span>
                      </div>
                    </Card>
                  ) : null}
                  {hasEC ? (
                    <Card className="w-full border-dirtySand/40 bg-darkSand p-4 sm:w-auto">
                      <div className="flex items-center gap-2 pb-2">
                        <AtomIcon className="h-4 w-4" />
                        <h4>Electrical Conductivity</h4>
                      </div>

                      <div className="flex items-center gap-2 text-sm text-concreteGray">
                        <span>Left:</span>
                        <span className="font-semibold">
                          {device.data?.ecLeft} μS/cm
                        </span>
                      </div>

                      <div className="flex items-center gap-2 text-sm text-concreteGray">
                        <span>Right:</span>
                        <span className="font-semibold">
                          {device.data?.ecRight} μS/cm
                        </span>
                      </div>
                    </Card>
                  ) : null}
                  {hasTemperature ? (
                    <Card className="w-full border-dirtySand/40 bg-darkSand p-4 sm:w-auto">
                      <div className="flex items-center gap-2 pb-2">
                        <ThermometerIcon className="h-4 w-4" />
                        <h4>Temperature</h4>
                      </div>

                      <div className="flex items-center gap-2 text-sm text-concreteGray">
                        <span>Left:</span>
                        <span className="font-semibold">
                          {device.data?.temperatureLeft}
                          {tempSuffix}
                        </span>
                      </div>

                      <div className="flex items-center gap-2 text-sm text-concreteGray">
                        <span>Right:</span>
                        <span className="font-semibold">
                          {device.data?.temperatureRight}
                          {tempSuffix}
                        </span>
                      </div>
                    </Card>
                  ) : null}
                </div>
              ) : null}
            </>
          )}
        </ErrorBoundary>
      </div>
      <div className="absolute bottom-4 right-4">
        {device.data?.timestamp ? (
          <span className="text-xs text-concreteGray">
            {t('device.details.updated')}{' '}
            {shortLocalizedDateWithTime(new Date(device.data.timestamp))}
          </span>
        ) : (
          <span className="text-xs text-concreteGray">
            {t('device.details.no_data_update')}{' '}
          </span>
        )}
      </div>
    </Card>
  )
}
export default DeviceIllustrationCard
