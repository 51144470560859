import * as Sentry from '@sentry/react'

import type { CreateUpdateMetadataDownlink } from '@/components/admin/AddEditMetadataDownlinkModal'
import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/MetadataDownlink'

export interface MetadataDownlink {
  id: number
  samples: number
  dataUplinkPeriod: number
  metadataUplinkPeriod: number
  fotaUplinkPeriod: number
  setCycleTime: number
  uplinkDiagnostic: number
  deviceId: number
  imei: string
  createdAt: string
  modifiedAt?: string
  deletedAt?: string
  createdBy: string
  modifiedBy?: string
  isDownlinkedToDevice: boolean
}

export interface PaginatedMetadataDownlinks {
  downlinks: MetadataDownlink[]
  totalCount: number
}

export const getPaginatedMetadataDownlinks = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  email?: string | null,
  take = 20,
  page = 0,
  from?: Date,
  to?: Date,
  includeDeleted?: boolean | null,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      includeDeleted: includeDeleted ? 'true' : 'false',
      ...(typeof imei === 'string' && { imei }),
      ...(typeof email === 'string' && { email }),
      ...(from && { from: from.toISOString() }),
      ...(to && { to: to.toISOString() }),
    })

    const res = await axiosClient.get<PaginatedMetadataDownlinks>(
      `${BASE_URI}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getMetadataDownlinkById = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.get(`${BASE_URI}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const createMetadataDownlink = async (
  data: CreateUpdateMetadataDownlink,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post(`${BASE_URI}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateMetadataDownlink = async (
  id: number,
  data: CreateUpdateMetadataDownlink,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URI}/${id}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const deleteMetadataDownlink = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.delete(`${BASE_URI}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
