import { TrashIcon } from '@radix-ui/react-icons'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import MaintenanceInstructionsIcon from '@/assets/icons/maintenanceinstructions.svg?react'
import MapPinIcon from '@/assets/icons/mappin.svg?react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { useToast } from '@/components/ui/use-toast'
import { useAuth } from '@/components/v2/AuthProvider'
import DetailedDeviceCard from '@/components/v2/DetailedDeviceCard'
import DeviceDataChart from '@/components/v2/DeviceDataChart'
import DeviceIllustrationCard from '@/components/v2/DeviceIllustrationCard'
import DeviceImageWithFallback from '@/components/v2/DeviceImageWithFallback'
import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge'
import LocationMapDialog from '@/components/v2/dialogs/LocationMapDialog'
import InternalNavigationBar from '@/components/v2/InternalNavigationBar'
import DeviceLoadingPage from '@/components/v2/skeletons/DeviceLoadingPage'
import type { DetailedDevice } from '@/data/device'
import useDevice from '@/hooks/useDevice'
import { isOwnerOrManager } from '@/utils/roleHelpers'
import { operatingWellStatuses, warningStatuses } from '@/utils/statusHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'
import { getShortDistanceUnitSuffix, getTempSuffix } from '@/utils/unitHelpers'

function DevicePage() {
  const { t } = useTranslation(['app', 'common'])
  const { deviceId, orgId } = useParams()
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const { toast } = useToast()
  const navigate = useNavigate()

  const {
    device,
    onOpenCloseValve,
    isLoading,
    onRestart,
    onDelete,
    isDeleting,
    onEnableDisableAutoRegulate,
    onUpdateCycleTime,
    onUpdateActiveSection,
  } = useDevice(deviceId || '', import.meta.env.VITE_DEVICES_POLLING_INTERVAL)

  const { user } = useAuth()
  const showWriteFunctionality = isOwnerOrManager(
    user?.organizationUsers?.find((ou) => ou.organizationId === Number(orgId))
      ?.role,
  )

  const getUnixSuffix = (device: DetailedDevice) => {
    return getShortDistanceUnitSuffix(device.distanceUnit)
  }

  const getTempUnitSuffix = (device: DetailedDevice) => {
    return getTempSuffix(device.temperatureUnit)
  }

  const getCardColor = (device: DetailedDevice) => {
    return operatingWellStatuses.includes(device.status)
      ? 'green'
      : warningStatuses.includes(device.status)
        ? 'yellow'
        : 'red'
  }

  const handleRestart = async () => {
    try {
      await onRestart()
      toast({
        title: `Device will restart next time it connects to the network`,
        description:
          'This might take a few minutes depending on how often the device sends data',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed restart device`,
        description:
          'Try again in a few minutes. Contact support if the problem keeps occuring',
        className: failureToastClassNames,
      })
    }
  }

  const handleDelete = async () => {
    try {
      await onDelete()
      toast({
        title: `Device deleted`,
        className: successfulToastClassNames,
      })
      // Remove last segment of url (= device id)
      const url = window.location.href.split('/').slice(0, -1)
      navigate(url.join('/'))
    } catch (err) {
      toast({
        title: `Failed to delete device`,
        description:
          'Try again in a few minutes. Contact support if the problem keeps occuring',
        className: failureToastClassNames,
      })
    }
  }

  const handleUpdateCycleTime = async (seconds: number) => {
    try {
      await onUpdateCycleTime(seconds)
      toast({
        title: `Cycle time updated`,
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed to update cycle time`,
        description:
          'Try again in a few minutes. Contact support if the problem keeps occuring',
        className: failureToastClassNames,
      })
    }
  }

  const handleUpdateAutoRegulate = async (shouldAutoRegulate: boolean) => {
    try {
      await onEnableDisableAutoRegulate(shouldAutoRegulate)
      toast({
        title: `Auto regulate has been ${
          shouldAutoRegulate ? 'enabled' : 'disabled'
        }`,
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed to update auto regulate`,
        description:
          'Try again in a few minutes. Contact support if the problem keeps occuring',
        className: failureToastClassNames,
      })
    }
  }

  const handleChangeActiveSection = async (section: number) => {
    try {
      await onUpdateActiveSection(section)
      toast({
        title: `Active section changed to ${section === 0 ? 'left' : 'right'}`,
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed to update active section`,
        description:
          'Try again in a few minutes. Contact support if the problem keeps occuring',
        className: failureToastClassNames,
      })
    }
  }

  return (
    <>
      <div className="mx-auto w-full max-w-4xl px-2 py-4 sm:p-4">
        <div className="flex flex-col gap-4">
          <InternalNavigationBar
            backLink={{
              href: `${window.location.pathname
                .split('/')
                .slice(0, -1)
                .join('/')}`,
              text: t('device.details.back_to_dashboard'),
            }}
            additionalLink={{
              href: `${window.location.pathname}/settings`,
              text: t('device.details.settings'),
            }}
            contextText={device?.name}
            onViewOnMapClick={() => setIsLocationModalOpen(true)}
          />
          {isLoading ? (
            <DeviceLoadingPage />
          ) : device ? (
            <>
              <DeviceIllustrationCard device={device} />

              {device.type === 'bunker' ? (
                <div className="relative overflow-hidden rounded-3xl">
                  <DeviceImageWithFallback
                    imageUrl={device.imageUrl}
                    id={device.id}
                    isDashboard={false}
                  />
                  <Button
                    className="absolute left-2 top-2 flex rounded-3xl bg-white text-xs text-black hover:bg-slate-100 sm:hidden"
                    onClick={() => setIsLocationModalOpen(true)}
                  >
                    <MapPinIcon />
                    {capitalizeFirstCharacter(
                      t('view_on_map', { ns: 'common' }),
                    )}
                  </Button>
                  {!operatingWellStatuses.includes(device.status) ? (
                    <div className="absolute bottom-2 right-2 block sm:hidden">
                      <DeviceStatusBadge status={device.status} />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                  <div className="relative w-full min-w-full flex-1 sm:min-w-min sm:max-w-[50%]">
                    <DeviceImageWithFallback
                      imageUrl={device.imageUrl}
                      id={device.id}
                      isDashboard={false}
                    />
                    <Button
                      className="absolute left-2 top-2 flex rounded-3xl bg-white text-xs text-black hover:bg-slate-100 sm:hidden"
                      onClick={() => setIsLocationModalOpen(true)}
                    >
                      <MapPinIcon />
                      {capitalizeFirstCharacter(
                        t('view_on_map', { ns: 'common' }),
                      )}
                    </Button>
                    {!operatingWellStatuses.includes(device.status) ? (
                      <div className="absolute bottom-2 right-2 block sm:hidden">
                        <DeviceStatusBadge status={device.status} />
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full min-w-full flex-1 sm:min-w-min sm:max-w-[50%]">
                    <DetailedDeviceCard
                      device={device}
                      onOpenCloseValve={onOpenCloseValve}
                      onEnableDisableAutoRegulate={async (shouldAutoRegulate) =>
                        handleUpdateAutoRegulate(shouldAutoRegulate)
                      }
                      onUpdateCycleTime={async (cycleTime) =>
                        handleUpdateCycleTime(cycleTime)
                      }
                      onUpdateActiveSection={async (section) =>
                        handleChangeActiveSection(section)
                      }
                      cardColor={getCardColor(device)}
                      showWriteFunctionality={showWriteFunctionality}
                    />
                  </div>
                </div>
              )}
              {device.type === 'bunker' ? (
                <div>
                  <div className="mt-8 flex items-center gap-4">
                    <h4 className="flex items-center gap-2 text-lg">
                      <MaintenanceInstructionsIcon />
                      {t('device.details.maintenance_instructions')}
                    </h4>
                    <hr className="flex-1" />
                  </div>
                  <div className="mt-8 flex flex-wrap items-center gap-8 md:flex-nowrap">
                    <iframe
                      width="100%"
                      height="100%"
                      className="aspect-video min-w-[60%] rounded-3xl"
                      src="https://www.youtube.com/embed/GAwac2iZ0yQ?si=8IHq7qxpC3fnyxYw"
                      title="CapillaryFlow bunker installation"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                      allowFullScreen={true}
                    ></iframe>
                    <div className="flex max-w-full flex-col gap-2 md:max-w-[50%]">
                      <h2 className="text-xl">
                        {t('device.details.maintenance_instructions_header')}
                      </h2>
                      <span>
                        {t(
                          'device.details.maintenance_instructions_description',
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <Card variant={getCardColor(device)}>
                  {deviceId ? (
                    <DeviceDataChart
                      deviceId={deviceId}
                      materialHeightBottom={device.materialHeight}
                      materialHeightTop={
                        device.materialHeight + (device.materialDepth || 0)
                      }
                      distanceUnitSuffix={getUnixSuffix(device)}
                      tempUnitSuffix={getTempUnitSuffix(device)}
                    />
                  ) : null}
                </Card>
              )}
            </>
          ) : null}
        </div>
        {showWriteFunctionality ? (
          <div className="mt-8 flex justify-end gap-2">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="outline"
                  className="border-red-700/50 text-red-700 hover:bg-red-50 hover:text-red-800"
                  isLoading={isDeleting}
                >
                  <span className="sr-only">Delete Device</span>
                  <div className="flex items-center gap-2">
                    <TrashIcon className="h-4 w-4" />
                    <span>Delete device</span>
                  </div>
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Are you sure you want to delete the Device{' '}
                    {device?.name || ''} with IMEI {device?.imei || ''}?
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    This action can not be undone
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    className="bg-red-500 hover:bg-red-500/90"
                    onClick={handleDelete}
                    disabled={isDeleting}
                  >
                    <span className={isDeleting ? 'invisible' : 'visible'}>
                      Delete
                    </span>
                    {isDeleting && (
                      <Loader2 className="absolute animate-spin" />
                    )}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <Button onClick={handleRestart}>Restart device</Button>
          </div>
        ) : null}
      </div>
      {device && device.lat && device.lng && (
        <LocationMapDialog
          device={device}
          isOpen={isLocationModalOpen}
          onClose={() => setIsLocationModalOpen(false)}
          showGetCurrent={false}
        />
      )}
    </>
  )
}
export default DevicePage
