const convertCelsiusToFahrenheit = (degreesInCelsius: number) => {
  if (Number.isNaN(degreesInCelsius)) return 0

  return degreesInCelsius * (9 / 5) + 32
}

const convertMillimeterValueToInches = (millimeters: number) => {
  if (Number.isNaN(millimeters)) return 0

  return Number((millimeters / 25.4).toFixed(2))
}

const convertMeterValueToFeet = (meters: number) => {
  if (Number.isNaN(meters)) return 0

  return Number((meters * 3.2808).toFixed(2))
}

const convertLiterValueToGallons = (liters: number) => {
  if (Number.isNaN(liters)) return 0

  return Number((liters * 0.264172).toFixed(2))
}

export const getTemperatureWithSuffixInUserDesiredUnit = (
  temperature?: number | null,
  temperatureUnit: string = 'celsius',
) => {
  if (typeof temperature !== 'number') return null
  if (temperatureUnit === 'celsius') return `${temperature} °C`

  return `${Math.round(convertCelsiusToFahrenheit(temperature))} °F`
}

export const getTempSuffix = (temperatureUnit?: string) => {
  if (temperatureUnit === 'fahrenheit') return '°F'

  return '°C'
}

export const getShortDistanceInUserDesiredUnit = (
  distanceUnit: string,
  value: number,
) => {
  if (distanceUnit === 'meter') return value

  return convertMillimeterValueToInches(value)
}

export const getLongDistanceInUserDesiredUnit = (
  distanceUnit: string,
  value: number,
) => {
  if (distanceUnit === 'meter') return value

  return convertMeterValueToFeet(value)
}

export const getVolumeInUserDesiredUnit = (
  distanceUnit: string,
  value: number,
) => {
  if (distanceUnit === 'meter') return value

  return convertLiterValueToGallons(value)
}

export const getShortDistanceUnitSuffix = (distanceUnit: string) => {
  return distanceUnit === 'feet' ? 'in' : 'mm'
}

export const getLongDistanceUnitSuffix = (distanceUnit: string) => {
  return distanceUnit === 'feet' ? 'ft' : 'm'
}

export const getVolumeUnitSuffix = (distanceUnit: string) => {
  return distanceUnit === 'feet' ? 'gal' : 'l'
}

export const getAreaUnitSuffix = (distanceUnit: string) => {
  return distanceUnit === 'feet' ? 'ft²' : 'm²'
}
