import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import type { MetadataDownlink } from '@/data/admin/metadataDownlink'
import useAdminMetadataDownlink from '@/hooks/useAdminMetadataDownlink'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import DeviceImeiAutocomplete from '../v2/selects/DeviceImeiAutocomplete'

const CreateUpdateMetadataDownlinkSchema = z.object({
  imei: z.string().length(15),
  samples: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  dataUplinkPeriod: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  metadataUplinkPeriod: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  fotaUplinkPeriod: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  setCycleTime: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  uplinkDiagnostic: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
})

export type CreateUpdateMetadataDownlink = z.infer<
  typeof CreateUpdateMetadataDownlinkSchema
>

const getDefaultValues = (
  data?: MetadataDownlink,
): CreateUpdateMetadataDownlink => ({
  imei: data?.imei || '',
  samples: data?.samples || -1,
  dataUplinkPeriod: data?.dataUplinkPeriod || -1,
  metadataUplinkPeriod: data?.metadataUplinkPeriod || -1,
  fotaUplinkPeriod: data?.fotaUplinkPeriod || -1,
  setCycleTime: data?.setCycleTime || -1,
  uplinkDiagnostic: data?.uplinkDiagnostic || -1,
})

function AddEditMetadataDownlinkModal() {
  const { t } = useTranslation(['admin', 'common'])
  const { toast } = useToast()
  const [id, setId] = useQueryParam('metadataDownlinkId', StringParam)
  const { data, onCreate, onUpdate } = useAdminMetadataDownlink(id)

  const isOpen = Boolean(id)
  const isNew = id === 'new'

  const form = useForm<CreateUpdateMetadataDownlink>({
    resolver: zodResolver(CreateUpdateMetadataDownlinkSchema),
    defaultValues: getDefaultValues(data),
  })

  useEffect(() => {
    if (data) {
      form.reset({ ...getDefaultValues(data) })
    }
  }, [data])

  const handleSubmit = async (data: CreateUpdateMetadataDownlink) => {
    try {
      if (isNew) {
        await onCreate(data)
      } else {
        await onUpdate({ id: Number(id!), data })
      }
      toast({
        title: `Metadata command ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      handleCloseAndReset()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} metadata command`,
        className: failureToastClassNames,
      })
    }
  }

  const handleCloseAndReset = () => {
    setId(null)
    form.reset({ ...getDefaultValues() })
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseAndReset}>
      <DialogContent className="max-h-[90%] max-w-3xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create metadata downlink' : 'Update metadata downlink'}
          </DialogTitle>
          <DialogDescription>
            -1 means that the field should not be updated
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="my-4 flex max-w-full flex-col gap-2 overflow-x-hidden px-1 sm:px-2"
          >
            <FormField
              control={form.control}
              name="imei"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>IMEI</FormLabel>
                  <FormControl>
                    <div className="w-full">
                      <DeviceImeiAutocomplete
                        imei={field.value || ''}
                        onSelect={(val) => {
                          field.onChange(val)
                        }}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="mt-4">
              <DialogDescription>
                The number of samples to be collected before sending a data
                point
              </DialogDescription>
            </div>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="samples"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Samples</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dataUplinkPeriod"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Data uplink period (seconds)</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-4">
              <DialogDescription>
                These are true/false values. 0 means false, 1 means true. Leave
                the field at -1 to do nothing
              </DialogDescription>
            </div>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="metadataUplinkPeriod"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Metadata uplink period (seconds)</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="fotaUplinkPeriod"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>FOTA uplink period (seconds)</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="setCycleTime"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormLabel>Cycle time (seconds)</FormLabel>
                  <FormControl>
                    <Input
                      variant={fieldState.error ? 'error' : 'default'}
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="uplinkDiagnostic"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormLabel>Uplink diagnostics</FormLabel>
                  <FormControl>
                    <Input
                      variant={fieldState.error ? 'error' : 'default'}
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-8">
              <Button variant="outline" onClick={handleCloseAndReset}>
                {t('modal.close_button', { ns: 'common' })}
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                {t('modal.save_button', { ns: 'common' })}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AddEditMetadataDownlinkModal
