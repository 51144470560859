import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'
import type { CreateUpdateDevice } from '@/utils/deviceHelpers'

import type { DataPoint } from './data-point'

export type DeviceType =
  | 'bunker'
  | 'green'
  | 'tee'
  | 'equestrian'
  | 'equestrian_grass'
  | 'equestrian_sand'
export type ValveState = 'open' | 'closed' | 'unknown'
export type BooleanState = 'on' | 'off' | 'unknown'
export type LeftRightState = 'left' | 'right' | 'unknown'
export type ValveType = 'fill' | 'drain' | 'dripLine'
export type DeviceWarningSeverity = 'none' | 'minor' | 'critical'
export type DistanceUnit = 'meter' | 'feet'
export type TemperatureUnit = 'celsius' | 'fahrenheit'

export const statuses = [
  'broken_data_connection',
  'low_water',
  'high_water',
  'scheduled_maintenance',
  'not_operating_optimally',
  'operating_well',
  'no_data_received',
  'error',
  'pending',
] as const

export type DeviceStatus = (typeof statuses)[number]
export interface Device {
  id: number
  name: string
  type: string
  imageUrl?: string
  updatedAt: string
  status: DeviceStatus
  organizationId: number
  lat: number
  lng: number
  data?: DataPoint
}

export interface DetailedDevice extends Device {
  materialHeight: number
  gravelDepth: number
  sandDepth: number
  materialPorosity: number
  gravelPorosity: number
  sandPorosity: number
  waterFlowRate: number
  fertigationFlowRate?: number
  desiredWaterLevel: number
  area: number
  notificationsEnabled: boolean
  imei: string
  materialDepth?: number
  distanceUnit: DistanceUnit
  temperatureUnit?: TemperatureUnit
  basinSpecId: number
  pipeDiameter: number
  pipeDistance: number
  usesDripLines?: boolean
  desiredNitrogenPPM?: number
  stockSolutionNitrogenAmount?: number
  sensorOffsetLeft: number
  sensorOffsetRight: number
}

export type PaginatedDevices = {
  totalDevices: number
  hasMore: boolean
  devices: Device[]
}
export type OpenCloseValve = {
  type: ValveType
  action: 'open' | 'close'
  minutes?: number
  desiredNitrogenPPM?: number | null
}

const getBaseUrl = (organizationId: string) =>
  `/organization/${organizationId}/device`

export const getDevices = async (
  organizationId: string,
  type?: string,
  status?: (string | null)[],
  searchText?: string,
  abortSignal?: AbortSignal,
): Promise<Device[]> => {
  try {
    const params = new URLSearchParams({
      status: status ? status.filter(Boolean)?.join(',') : '',
      type: type || '',
      ...(searchText && { q: searchText }),
    })

    const res = await axiosClient.get(
      `${getBaseUrl(organizationId)}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { type, status, searchText } })
    throw err
  }
}

export const getPaginatedDevices = async (
  organizationId: string,
  page: number,
  take: number,
  type?: string,
  status?: (string | null)[],
  searchText?: string,
  abortSignal?: AbortSignal,
): Promise<Device[]> => {
  const params = new URLSearchParams({
    page: page.toString(),
    take: take.toString(),
    status: status ? status.filter(Boolean)?.join(',') : '',
    type: type || '',
    ...(searchText && { q: searchText }),
  })
  const res = await axiosClient.get(
    `${getBaseUrl(organizationId)}?${params.toString()}`,
    {
      signal: abortSignal,
    },
  )

  return res.data
}

export const getOverviewStatus = async (
  organizationId: string,
  abortSignal?: AbortSignal,
) => {
  try {
    const res = await axiosClient.get(
      `${getBaseUrl(organizationId)}/overview-status`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getDevice = async (
  organizationId: string,
  id: string,
  abortSignal?: AbortSignal,
): Promise<DetailedDevice> => {
  try {
    const res = await axiosClient.get(`${getBaseUrl(organizationId)}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { deviceId: id } })
    throw err
  }
}

export const updateDevice = async (
  organizationId: string,
  id: string,
  device: CreateUpdateDevice,
) => {
  try {
    const res = await axiosClient.put(
      `${getBaseUrl(organizationId)}/${id}`,
      device,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, {
      extra: { deviceId: id, device: JSON.stringify(device) },
    })
    throw err
  }
}

export const createDevice = async (
  organizationId: string,
  device: CreateUpdateDevice,
) => {
  try {
    const res = await axiosClient.post(`${getBaseUrl(organizationId)}`, device)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { device: JSON.stringify(device) } })
    throw err
  }
}

export const setValveState = async (
  organizationId: string,
  id: string,
  type: ValveType,
  minutes = 0,
  desiredNitrogenPPM?: number | null,
) => {
  try {
    const res = await axiosClient.put(
      `${getBaseUrl(organizationId)}/${id}/${type.toLowerCase()}-valve`,
      {
        minutes,
        desiredNitrogenPPM,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { deviceId: id, minutes } })
    throw err
  }
}

export const restartDevice = async (organizationId: string, id: string) => {
  try {
    const res = await axiosClient.post(
      `${getBaseUrl(organizationId)}/${id}/restart`,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { deviceId: id } })
    throw err
  }
}

export const deleteDevice = async (organizationId: string, id: string) => {
  try {
    const res = await axiosClient.delete(`${getBaseUrl(organizationId)}/${id}`)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { deviceId: id } })
    throw err
  }
}

export const updateCycleTime = async (
  organizationId: string,
  id: string,
  seconds: number,
) => {
  try {
    const res = await axiosClient.put(
      `${getBaseUrl(organizationId)}/${id}/cycle-time`,
      {
        seconds,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { deviceId: id, seconds } })
    throw err
  }
}

export const updateAutoRegulate = async (
  organizationId: string,
  id: string,
  shouldAutoRegulate: boolean,
) => {
  try {
    const res = await axiosClient.put(
      `${getBaseUrl(organizationId)}/${id}/auto-regulate`,
      {
        shouldAutoRegulate,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, {
      extra: { deviceId: id, shouldAutoRegulate },
    })
    throw err
  }
}

export const setActiveSection = async (
  organizationId: string,
  id: string,
  activeSection: number,
) => {
  try {
    const res = await axiosClient.put(
      `${getBaseUrl(organizationId)}/${id}/active-section`,
      {
        activeSection,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, {
      extra: { deviceId: id, activeSection },
    })
    throw err
  }
}
