import { useState } from 'react'
import {
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import type { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'

import type { ChartData } from '@/data/device-data'
import {
  localizedDateWithTime,
  shortLocalizedDateWithTime,
  shortLocalizedDateWithoutTime,
  timeWithoutDate,
} from '@/utils/dateHelpers'

function ECChart({
  data,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  isZoomed,
  showTimeOnly,
  showCondensed,
  unitSuffix,
  zoomArea,
  showZoomBox,
}: {
  data: ChartData[]
  onMouseDown?: CategoricalChartFunc
  onMouseMove: CategoricalChartFunc
  onMouseUp: CategoricalChartFunc
  isZoomed: boolean
  showTimeOnly: boolean
  showCondensed: boolean
  unitSuffix: string
  zoomArea: { x1: string | null; x2: string | null }
  showZoomBox: boolean
}) {
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([])

  const toggleSeries = (key: string) => {
    if (hiddenSeries.includes(key)) {
      setHiddenSeries(hiddenSeries.filter((item) => item !== key))
    } else {
      setHiddenSeries([...hiddenSeries, key])
    }
  }

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className="plot-container py-2"
    >
      <LineChart
        data={data}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <>
          <XAxis
            dataKey="timestamp"
            domain={isZoomed ? ['dataMin - 3', 'dataMax + 3'] : undefined}
            tickFormatter={(val) =>
              showTimeOnly
                ? timeWithoutDate(new Date(val))
                : showCondensed
                  ? shortLocalizedDateWithTime(new Date(val))
                  : shortLocalizedDateWithoutTime(new Date(val))
            }
            className="whitespace-break-spaces text-xs"
          />
          <YAxis
            yAxisId="left"
            tickFormatter={(val) => `${val} ${unitSuffix}`}
            domain={
              isZoomed
                ? [
                    (dataMin: number) => Math.max(0, dataMin - 5),
                    (dataMax: number) => dataMax + 5,
                  ]
                : [0, (dataMax: number) => dataMax + 5]
            }
            className="whitespace-nowrap text-xs"
          />
          <Tooltip
            labelFormatter={(val) => localizedDateWithTime(new Date(val))}
            wrapperStyle={{ fontSize: '12px' }}
            formatter={(label) => `${label}`}
          />
          <Legend
            wrapperStyle={{ fontSize: '12px' }}
            onClick={(e) => toggleSeries(e.dataKey as string)}
            formatter={(value, entry) => (
              <span
                className="cursor-pointer select-none"
                style={{
                  // @ts-expect-error entry type does not include dataKey
                  color: hiddenSeries.includes(entry.dataKey as string)
                    ? '#ccc'
                    : entry.color,
                }}
              >
                {value}
              </span>
            )}
          />
          <Line
            type="monotone"
            dataKey="averageEcLeft"
            stroke={
              hiddenSeries.includes('averageEcLeft') ? 'transparent' : '#0079BF'
            }
            strokeWidth={2}
            dot={false}
            name={`Left section (${unitSuffix})`}
            yAxisId="left"
          />
          <Line
            type="monotone"
            dataKey="averageEcRight"
            stroke={
              hiddenSeries.includes('averageEcRight')
                ? 'transparent'
                : '#82ca9d'
            }
            strokeWidth={2}
            dot={false}
            name={`Right section (${unitSuffix})`}
            yAxisId="left"
          />

          {showZoomBox && (
            <ReferenceArea
              yAxisId="left"
              x1={zoomArea.x1 ?? undefined}
              x2={zoomArea.x2 ?? undefined}
              ifOverflow="extendDomain"
            />
          )}
        </>
      </LineChart>
    </ResponsiveContainer>
  )
}
export default ECChart
