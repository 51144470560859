import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/datapoint'

export interface DetailedDataPoint {
  id: number
  timestamp: string
  waterLevelLeft?: number
  waterLevelRight?: number
  waterLevelPercentage?: number
  fillValveState?: number
  drainValveState?: number
  dripLineValveState?: number
  fertigationValveState?: number
  balanceValveState?: number
  status?: string
  temperatureLeft?: number
  temperatureRight?: number
  moistureLeft?: number
  moistureRight?: number
  ecLeft?: number
  ecRight?: number
  varianceLeft?: number
  varianceRight?: number
  activeSection?: number
  autoRegulate?: boolean
  cycleTime?: number
  firmwareVersion?: string
  deviceId: number
  IMEI: string
  createdAt: string
}

export interface PaginatedDataPoints {
  dataPoints: DetailedDataPoint[]
  totalCount: number
}

export const getDataPoints = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  take = 20,
  page = 0,
  from?: Date,
  to?: Date,
  orgId?: string | null,
  filters?: string,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      ...(typeof imei === 'string' && { imei }),
      ...(from && { from: from.toISOString() }),
      ...(to && { to: to.toISOString() }),
      ...(orgId && { orgId }),
      ...(filters && { filters }),
    })

    const res = await axiosClient.get<PaginatedDataPoints>(
      `${BASE_URI}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
