import { z } from 'zod'

import {
  getLongDistanceInUserDesiredUnit,
  getShortDistanceInUserDesiredUnit,
  getVolumeInUserDesiredUnit,
} from './unitHelpers'

export const getDeviceDefaultValuesInLocalUnit = (distanceUnit: string) => {
  return {
    materialHeight: getShortDistanceInUserDesiredUnit(distanceUnit, 750),
    materialDepth: getShortDistanceInUserDesiredUnit(distanceUnit, 50),
    materialPorosity: 20,
    gravelDepth: getShortDistanceInUserDesiredUnit(distanceUnit, 50),
    gravelPorosity: 35,
    sandDepth: getShortDistanceInUserDesiredUnit(distanceUnit, 200),
    sandPorosity: 5,
    desiredWaterLevel: getShortDistanceInUserDesiredUnit(distanceUnit, 800),
    waterFlowRate: getVolumeInUserDesiredUnit(distanceUnit, 2),
    fertigationFlowRate: getVolumeInUserDesiredUnit(distanceUnit, 1),
    pipeDistance: getLongDistanceInUserDesiredUnit(distanceUnit, 5),
    pipeDiameter: getShortDistanceInUserDesiredUnit(distanceUnit, 50),
    stockSolutionNitrogenAmount: null,
    desiredNitrogenPPM: null,
  }
}

export const DeviceSchema = z.object({
  name: z.string().min(1, 'This field is required'),
  type: z.string(),
  materialHeight: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  gravelDepth: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  sandDepth: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  materialDepth: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  sandPorosity: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0).max(100),
  ),
  gravelPorosity: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0).max(100),
  ),
  materialPorosity: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0).max(100),
  ),
  desiredWaterLevel: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  pipeDistance: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  pipeDiameter: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  waterFlowRate: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  fertigationFlowRate: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0).optional().nullable(),
  ),
  area: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  stockSolutionNitrogenAmount: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().optional().nullable(),
  ),
  desiredNitrogenPPM: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0).optional().nullable(),
  ),
  lat: z.number(),
  lng: z.number(),
  coordinates: z.string().optional(),
  imageUrl: z.string().nullable().optional(),
  notificationsEnabled: z.boolean(),
  organizationId: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  basinSpecId: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  usesDripLines: z.boolean().nullable().optional(),
  updatedAt: z.string().nullable().optional(),
  imei: z.string().length(15, 'Must be exactly 15 digits'),
})

export type CreateUpdateDevice = z.infer<typeof DeviceSchema>
